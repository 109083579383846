import React from 'react';
import { Link } from 'gatsby'
// import './style.scss';
import logo from '../images/logo_footer.svg'
import kjg_logo from '../images/kjg_logo.png'
import x_logo from '../images/x_logo.png'
import xenadinLogo from '../images/xeinadin-logo.svg'
import CookieConsent from "react-cookie-consent";

const Footer = () => (
	<footer className="footer center has-background-black has-text-white-ter">
		<CookieConsent
			location="bottom"
			buttonText="Accept"
			cookieName="ComplianceCookie"
			style={{ background: "#2B373B" }}
			buttonStyle={{ background: "#17a9bf", color: "#fff", fontSize: "13px" }}
			expires={150}
		>
			This website uses cookies to improve your experience. {" "}
			<span style={{ color: "#17a9bf" }}>
				<Link to="/cookies" style={{ color: "#17a9bf" }}> Find Out More</Link>
			</span>

		</CookieConsent>
		<div className="container">
			<div className="columns">
				<div className="column is-8 tih-logo-column">
					<img className="footer-tih-logo"
						src={logo}
						alt="The Insolvency Helpdesk"
					/>
					<p className="footer-text has-text-grey-light is-size-7-tablet is-size-7-mobile is-size-7-widescreen"><b>© {new Date().getFullYear()} | SMEHUB Business Solutions LIMITED</b> <br /> Marple House, Stockport Road, Marple, SK6 6BD <br /> Registered in England & Wales: 07219429</p>

				</div>

				<div className="column is-4 other-footer-logos">
					<a className="footer-logos kjg-logo" href="https://www.kjgllp.com" target="_blank"><img
						src={kjg_logo}
						alt="Kay Johnson Gee"
					/></a>
					<a className="footer-logos x-logo" href="https://www.xeinadin-group.com/" target="_blank"><img
						src={xenadinLogo}
						alt="Xeinadin Group"
					/></a>
					<p className="footer-text-right has-text-grey-light is-size-7-tablet is-size-7-mobile is-size-7-widescreen">Kay Johnson Gee Corporate Recovery Limited is a member of the Xeinadin Group Limited | 6 Old Jewry, Bank, London, EC2R 8DD | Registered in England No 11354408
				<br />
				The Xeinadin Group has 88 member firm offices throughout the UK enabling us to provide local advice with national reach with a focus on SME and owner managed businesses.</p>
				</div>
			</div>
			<p className="footer-text-lower has-text-grey-light is-size-7-tablet is-size-7-mobile is-size-7-widescreen">Insolvency advice provided on behalf of The Insolvency Helpdesk is provided by Licensed Insolvency Practitioners from Kay Johnson Gee Corporate Recovery Limited
			<br />1 City Road East, Manchester, M15 4PN | Registered in England No 11415156
					<br />
					Boutique Insolvency and Restructuring Firm of the Year at the 2019 Turnaround, Restructuring and Insolvency Awards. All of our Insolvency Practitioners are
					licensed by the Institute of Chartered Accountants in England and Wales.</p>
		</div>

	</footer>
);


export default Footer;

import React from 'react';
import { Link } from 'gatsby'
import '../components/style.scss';

import tihLogo from '../images/tih-logo.svg';
import locationIcon from '../images/location-icon.svg'

const Navbar = class extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			active: false,
			navBarActiveClass: '',
			menuIndex: null,
			dropActive: false,
		}
	}

	componentDidMount() {

	}

	toggleMobileDropDown = (index) => {
		this.setState({
			menuIndex: index,
			dropActive: !this.state.dropActive,
		})
		console.log("Menu Index: " + this.state.menuIndex);
	}

	toggleHamburger = () => {
		// toggle the active boolean in the state
		this.setState(
			{
				active: !this.state.active,
			},
			// after state has been updated,
			() => {
				// set the class in state for the navbar accordingly
				this.state.active
					? this.setState({
						navBarActiveClass: 'is-active',
					})
					: this.setState({
						navBarActiveClass: '',
					})
			}
		)
	}
	render() {
		const { mobileDropDownActive, navBarActiveClass } = this.state;
		console.log("Mobile DropDown: " + mobileDropDownActive);

		return (
			<nav
				className="navbar is-white"
				// className="navbar is-fixed-top is-white"
				role="navigation"
				aria-label="main-navigation"
			>

				{/* <br /> */}
				<div className="container">
					<div className="navbar-brand">
						<Link to="/" className="navbar-item" title="Logo">
							<img src={tihLogo} alt="The Insolvency Helpdesk" style={{ width: '150px' }} />
						</Link>
						{/* Hamburger menu */}
						<div
							className={`navbar-burger burger ${navBarActiveClass}`}
							data-target="navMenu"
							onClick={() => this.toggleHamburger()}
						>
							<span />
							<span />
							<span />
						</div>
					</div>

					<div
						id="navMenu"
						className={`navbar-menu ${navBarActiveClass}`}
					>
						<div className="navbar-loc-number"><div className="navbar-locations is-hidden-mobile">88 offices throughout the UK</div><div className="navbar-number"><a className="tel-number" href="tel:0800 066 3122">Call us free on 0800 066 3122</a></div></div>

						<div className="navbar-start">
							<span className={`navbar-item has-dropdown is-hoverable ${this.state.menuIndex === 0 && this.state.dropActive ? 'is-active-mobile' : ''}`} onClick={() => this.toggleMobileDropDown(0)}>
								Closure & Liquidation
								<div className="navbar-dropdown is-boxed">
									<Link className="navbar-item" to="/how-to-close-your-company">How To Close Your Company</Link>
									<Link className="navbar-item" to="/creditors-voluntary-liquidation">Creditors' Voluntary Liquidation (CVL)</Link>
									<Link className="navbar-item" to="/members-voluntary-liquidation">Members Voluntary Liquidation</Link>
									<Link className="navbar-item" to="/redundancy">Redundancy</Link>
									<Link className="navbar-item" to="/liquidation-costs">Liquidation Costs</Link>
									<Link className="navbar-item" to="/directors-duties">Directors' Duties & Potential Liabilities</Link>
									<Link className="navbar-item" to="/being-a-director-again">Being a Director Again</Link>
									<Link className="navbar-item" to="/reusing-a-company-name">Reusing a Company Name</Link>
									<Link className="navbar-item" to="/role-of-the-liquidator">The Role of the Liquidator</Link>
								</div>
							</span><span className="blue-lined"></span>
							<span className={`navbar-item has-dropdown is-hoverable ${this.state.menuIndex === 1 && this.state.dropActive ? 'is-active-mobile' : ''}`} onClick={() => this.toggleMobileDropDown(1)}>
								Business Debt
								<div className="navbar-dropdown is-boxed">
									<Link className="navbar-item" to="/dealing-with-business-debt">Dealing With Business Debt</Link>
									<Link className="navbar-item" to="/dealing-with-business-cash-flow-problems">Dealing With Business Cash Flow Problems</Link>
									<Link className="navbar-item" to="/tax-arrears-solutions">VAT, Tax & PAYE Arrears Solutions</Link>
									<Link className="navbar-item" to="/your-options-on-receiving-a-winding-up-petition">Your Options On Receiving A Winding Up Petition</Link>

								</div>
							</span><span className="blue-lined"></span>
							<span className={`navbar-item has-dropdown is-hoverable ${this.state.menuIndex === 2 && this.state.dropActive ? 'is-active-mobile' : ''}`} onClick={() => this.toggleMobileDropDown(2)}>
								Turnaround & Rescue
								<div className="navbar-dropdown is-boxed">
									<Link className="navbar-item" to="/administration">Administration</Link>
									<Link className="navbar-item" to="/company-voluntary-arrangement">Company Voluntary Arrangement (CVA)</Link>
									<Link className="navbar-item" to="/setting-up-a-new-business">Setting up</Link>
									<Link className="navbar-item" to="/funding-options">Funding</Link>

								</div>
							</span><span className="blue-lined"></span>
							<span className={`navbar-item has-dropdown is-hoverable ${this.state.menuIndex === 3 && this.state.dropActive ? 'is-active-mobile' : ''}`} onClick={() => this.toggleMobileDropDown(3)}>Real Client Stories
								<div className="navbar-dropdown is-boxed">
									<Link className="navbar-item" to="/client-stories/it-consultant-closure">IT Consultant – Company closure</Link>
									<Link className="navbar-item" to="/client-stories/clothing-wholesaler-liquidation">Clothing & PPE Wholesaler – Liquidation</Link>
									<Link className="navbar-item" to="/client-stories/haulier-cva">Haulier - Company Voluntary Agreement (CVA)</Link>
									<Link className="navbar-item" to="/client-stories/bespoke-furniture-manufacturer">Bespoke Furniture Manufacturer - Administration</Link>
									<Link className="navbar-item" to="/client-stories/construction-company">Construction Company - Liquidation</Link>
									<Link className="navbar-item" to="/client-stories/outsourced-employment-company">Outsourced Employment Company - Pre-Pack</Link>

								</div>
							</span><span className="blue-lined"></span>
							<span className={`navbar-item has-dropdown is-hoverable ${this.state.menuIndex === 4 && this.state.dropActive ? 'is-active-mobile' : ''}`} onClick={() => this.toggleMobileDropDown(4)}>
								Insolvency Insights
								<div className="navbar-dropdown is-boxed">
									<Link className="navbar-item" to="/insolvency-insights/cvl-directors-perspective">Liquidation - A Directors Perspective</Link>
									<Link className="navbar-item" to="/insolvency-insights/insolvent">What Does Insolvent Really Mean?</Link>
									<Link className="navbar-item" to="/insolvency-insights/how-does-administration-work">How Does Administration Work?</Link>
									<Link className="navbar-item" to="/insolvency-insights/tax-arrears">Dealing with VAT, Tax & PAYE arrears</Link>
									<Link className="navbar-item" to="/insolvency-insights/realising-value-through-mvl">Realising business value through MVL</Link>
									<Link className="navbar-item" to="/insolvency-insights/six-key-measures-of-performance">Six Key Measures Of Performance</Link>

								</div>
							</span><span className="blue-lined"></span>
							<Link className="navbar-item" to="/about">
								About Us
              				</Link><span className="blue-lined"></span>
							<Link className="navbar-item" to="/contact-us">
								Contact Us
              				</Link>
						</div>
						<div className="navbar-end has-text-centered">

						</div>
					</div>
				</div>
				<div className="mobile-number is-hidden-desktop"><a href="tel:0800 066 3122">Call us free on 0800 066 3122</a></div>
			</nav>

		)
	}
}

export default Navbar
